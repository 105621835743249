<template>
    <div>
        <div class="ft20 cl-main ftw600">服务人员</div>

        <div class="mt20">
            <div class="form-search-box ">
                <a-form layout="inline">
                    <div>
                        <a-form-item label="关键词">
                            <a-input v-model="search.keywords" placeholder="请输入工号/手机号/姓名"></a-input>
                        </a-form-item>

                        <a-form-item label="所在站点">
                            <a-select style="width: 350px" placeholder="全部站点" v-model="search.shop_id" mode="multiple">
                                <a-select-option :value="item.shop_id" :key="index"
                                                 v-for="(item,index) in shop_list">{{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item label="持证情况">
                            <a-select v-model="search.aptitude_list" style="width:200px" placeholder="持证情况" mode="multiple">
                                <a-select-option :value="1" :key="0">健康证</a-select-option>
                                <a-select-option :value="2" :key="1">护理证</a-select-option>
                                <a-select-option :value="3" :key="2">护士证</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="服务范围">
                            <a-select v-model="search.select_servers" style="width:200px" placeholder="服务范围" mode="multiple">
                                <a-select-option :value="item.value" :key="index"
                                                 v-for="(item,index) in select_servers_list">{{ item.label }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                    </div>
                    <div>
                        <a-form-item label="性别">
                            <a-select v-model="search.sex" style="width:100px" placeholder="性别" mode="multiple">
                                <a-select-option :value="2" :key="0">男</a-select-option>
                                <a-select-option :value="1" :key="1">女</a-select-option>
                                <a-select-option :value="0" :key="2">未知</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="状态">
                            <a-select v-model="search.status" style="width:100px" placeholder="状态">
                                <a-select-option :value="-1" :key="0">离职</a-select-option>
                                <a-select-option :value="1" :key="1">在职</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="注册时间">
                            <a-range-picker show-time @change="changeTime"></a-range-picker>
                        </a-form-item>

                        <a-form-model-item label="年龄段" >
                            <a-input-group >
                                <a-row :gutter="8">
                                    <a-col :span="8"><a-input v-model="search.min_age" addon-before="最小年龄"/></a-col>
                                    <a-col :span="8"><a-input v-model="search.max_age" addon-before="最大年龄"/></a-col>
                                </a-row>

                            </a-input-group>
                        </a-form-model-item>

                    </div>

                    <a-form-item>
                        <a-button @click="searchAct" icon="search" type="primary">查询</a-button>
                        <a-button @click="cancelAct" icon="close" class="ml10">取消</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>

        <div class="mt20">
            <div class="bg-w">
                <div class="pd30">
                    <div>
                        <a-button type="primary" icon="plus" @click="addServicemanAct()">添加服务人员</a-button>
<!--                        <a-button type="primary" icon="arrow-up" @click="addServicemanAct()">导入</a-button>-->
<!--                        <a-button type="primary" icon="arrow-down" @click="addServicemanAct()">导出</a-button>-->
                    </div>


                    <div class="mt20">
                        <div class="wxb-table-gray">
                            <a-table rowKey="serviceman_id" :columns="columns" :pagination="pagination"
                                     @change="handleTableChange"
                                     :data-source="datas" :loading="loading">

                                <div class="flex alcenter left" slot="project" slot-scope="record">
                                    <img :src="record.face" class="site-index-cover-img"/>
                                    <div class="ml5" style="text-align: left;">
                                        <div>
                                          {{ record.name }} <span class="ft12">{{ record.age }}</span>
                                          <a-tag class="ml5" v-if="record.status == 1" color="green">在职</a-tag>
                                          <a-tag class="ml5" v-if="record.status == -1" color="red">离职</a-tag>
                                        </div>
                                        <div class="ft12">站点：{{ record.shop_name }}</div>
                                        <div class="ft12">电话：{{ record.mobile }}</div>
                                    </div>
                                </div>

                                <div slot="aptitude_list_arr" slot-scope="aptitude_list_arr" class="aptitude_list_arr">
                                    <div v-for="(val,index) in aptitude_list_arr" :key="index" style="margin-bottom: 2px; float: left">
                                        <a-tag color="purple">{{val}} </a-tag>
                                    </div>
                                </div>
                                <div slot="select_servers_arr" slot-scope="select_servers_arr" class="aptitude_list_arr">
                                    <div v-for="(val,index) in select_servers_arr" :key="index" style="margin-bottom: 2px; float: left">
                                        <a-tag color="black" class="fl">{{val}}</a-tag>
                                    </div>
                                </div>

                                <div class="flex alcenter center" slot="type" slot-scope="type">
                                    {{ type != null ? type.name : '暂无分类' }}
                                </div>

                                <template slot="action" slot-scope="record,index">
                                    <div class="flex center">
                                        <a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
                                            <a-menu slot="overlay">
                                                <a-menu-item>
                                                    <a class="menu-act" href="javascript:;"
                                                       @click="editServicemanAct(record)">
                                                        <i class="iconfont ft14 iconedit"></i>
                                                        <span class="ml10">编辑</span>
                                                    </a>
                                                </a-menu-item>
                                            </a-menu>
                                        </a-dropdown>
                                    </div>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="editServicemanVisible">
            <edit-serviceman :serviceman_id="serviceman_id" :visible="editServicemanVisible" @cancel="cancelEditServiceman" @ok="okEditServiceman"></edit-serviceman>
        </div>
    </div>

</template>

<script>
import {listMixin} from '@/common/mixin/list';
import EditServiceman from "./components/EditServiceman.vue";
export default {
    components: {EditServiceman},
    mixins: [listMixin],
    comments:{
        EditServiceman
    },
    data() {
        return {
            loading: false,
            editServicemanVisible:false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
                showTotal:total=>`共${total} 条数据`
            },
            shop_list:[],
            select_servers_list:[],
            search: {
                keywords: '',
                add_time: null,
                status: '',
                shop_id: [],
                aptitude_list: [],
                select_servers: [],
                sex: [],
                min_age: null,
                max_age: null,
            },
            columns: [
                {title: '工号', dataIndex: 'job_number', width: 100, align: 'center'},
                {title: '账号', key: 'project', align: 'left', width: 280, scopedSlots: {customRender: 'project'}},
                {title: '持证情况', dataIndex: 'aptitude_list_arr', align: 'left',  ellipsis: true,scopedSlots: {customRender: 'aptitude_list_arr'}},
                {title: '服务范围', dataIndex: 'select_servers_arr', align: 'left', ellipsis: true,scopedSlots: {customRender: 'select_servers_arr'}},
                {title: '操作', key: 'action',width: 130, align: 'center', scopedSlots: {customRender: 'action'}}
            ],
            datas: [],
        }
    },
    methods: {
        getLists() {
            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('platform/getServicemanList', {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                keywords:this.search.keywords,
                add_time:this.search.add_time,
                status:this.search.status,
                shop_id:this.search.shop_id,
                aptitude_list:this.search.aptitude_list,
                select_servers:this.search.select_servers,
                sex:this.search.sex,




            }).then(res => {
                this.pagination.total = res.total;
                this.datas = res.list;
                this.shop_list = res.shop_list;
                this.select_servers_list = res.select_servers_list;
                this.loading = false;
            }).catch(res => {
                this.loading = false;
                console.log(res);
            })
        },
        changeTime(date, dateString){
            this.search.add_time = dateString;

        },
        //
        // delProjectAct(record) {
        //     this.$confirm({
        //         title: '确认删除这个项目吗？',
        //         okText: "确定",
        //         okType: "danger",
        //         cancelText: "取消",
        //         onOk: () => {
        //             return new Promise((resolve, reject) => {
        //                 this.$http.api('platform/delProject', {
        //                     project_id: record.project_id,
        //                 }).then(res => {
        //                     this.$message.success('删除成功', 1, () => {
        //                         this.getLists();
        //                     });
        //                 }).catch(res => {
        //                     console.log(res);
        //                 }).finally(() => {
        //                     resolve();
        //                 })
        //             })
        //         }
        //     })
        // },
        changeStatus() {
            this.pagination.current = 1;
            this.getLists();
        },
        //编辑服务人员
        addServicemanAct(){
            this.serviceman_id=0;
            this.editServicemanVisible=true;
        },
        editServicemanAct(record){
            this.serviceman_id = record.serviceman_id;
            this.editServicemanVisible=true;
        },
        cancelEditServiceman(){
            this.editServicemanVisible=false;
        },
        okEditServiceman(){
            this.editServicemanVisible=false;
            this.getLists();
        },



        addSiteAct() {
            this.$router.replace('/siteAdmin/edit');
        },
        editProjectAct(record) {
            this.$router.push('/siteAdmin/edit?id=' + record.shop_id);
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
        del(record){
            if(record.is_open === 1){
                this.$message.error('此站点为启用状态无法删除');
                return false;
            }
            this.$confirm({
                title: '确认删除这个站点吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.api('platform/site/delSite', {
                            shop_id: record.shop_id,
                        }).then(res => {
                            this.$message.success('删除成功', 1, () => {
                                this.getLists();
                            });
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        },
    }
}
</script>

<style>
.site-index-cover-img{
    width: 40px;
    height: 40px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
}
.ant-btn{
    margin-right: 8px;
    margin-bottom: 5px;
}
</style>
