<template>
    <div>
        <a-modal :title="getTitle" :width="480" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <a-spin :spinning="loading">

                <div>
                    <div class="flex alcenter">
                        <div class="ft14 ftw400 cl-info">服务人员头像：</div>
                        <div class="ft12 ftw400 cl-notice ml8">建议上传200x200px</div>
                    </div>
                    <div class="flex center mt10">
                        <upload-img v-model="form.face"></upload-img>
                    </div>
                </div>

                <div class="flex  mt10">
                    <a-input class="woyaou-input" style="width: 230px;" prefix="姓名" placeholder="请输入姓名"
                             v-model="form.name" :maxLength="20">
                    </a-input>
                    <a-input class="woyaou-input ml20" prefix="手机号" style="width: 230px;"
                             placeholder="请输入手机号"
                             v-model="form.mobile" :maxLength="20">
                    </a-input>
                </div>

                <div class="flex  mt10">
                    <a-input class="woyaou-input " prefix="工号" style="width: 480px;" placeholder="请输入服务人员工号"
                             v-model="form.job_number" :maxLength="20">
                    </a-input>
                </div>
                <div class="flex  mt10">
                    <div class="ft12 ftw500 cl-info" style="width: 60px;">性别：</div>
                    <a-radio-group v-model="form.sex">
                        <a-radio :value="2">男</a-radio>
                        <a-radio :value="1">女</a-radio>
                    </a-radio-group>
                </div>
                <div class="flex  mt10">
                    <div class="ft12 ftw500 cl-info" style="width: 60px;">出生日期：</div>
                    <a-date-picker
                        format="YYYY-MM-DD"
                        @change="changeTime"
                        :default-value="moment(form.birthday, dateFormat)"
                        :value="form.birthday"
                    />
                </div>

                <div class="flex  mt10">
                    <div class="ft12 ftw500 cl-info" style="width: 60px;">所属站点：</div>
                    <a-select style="width: 350px" placeholder="全部站点" v-model="form.shop_id" >
                        <a-select-option :value="item.shop_id" :key="index"
                                         v-for="(item,index) in shop_list">{{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="flex  mt10">
                    <div class="ft12 ftw500 cl-info" style="width: 60px;">状态：</div>
                    <a-switch checked-children="正常" un-checked-children="离职"  v-model="form.status"/>
                </div>
                <div class="mt20">
                    <div class="flex ">
                        <div class="ft12 ftw500 cl-info" style="width: 60px;">服务资质：</div>
                        <div class="ml2">
                            <a-checkbox-group
                                    v-model="form.aptitude_list"
                                    :options="aptitude_list"
                                    :value="form.aptitude_list"
                            >
                            </a-checkbox-group>
                        </div>
                    </div>
                </div>


                <div class="mt20">
                    <div class="flex ">
                        <div class="ft12 ftw500 cl-info" style="width: 140px;">可接服务：</div>
                        <div class="ml2">
                            <a-checkbox-group
                                    v-model="form.select_servers"
                                    :options="service_lists"
                                    :value="form.select_servers"
                            >
                            </a-checkbox-group>
                        </div>
                    </div>
                </div>
                <div class="flex  mt10">
                    <div class="ft12 ftw500 cl-info" style="width: 60px;">学历：</div>
                    <a-select placeholder="请选择学历"
                              v-model="form.education_id"
                              :options="education_list" style="width: 200px">
                    </a-select>
                </div>
                <div class="mt20">
                    <div class="flex ">
                        <div class="ft12 ftw500 cl-info" style="width: 60px;">聘用方式：</div>
                        <div class="ml2">
                            <a-radio-group v-model="form.employ">
                                <a-radio :value="0">
                                    全日制聘用
                                </a-radio>
                                <a-radio :value="1">
                                    退休返聘
                                </a-radio>
                                <a-radio :value="2">
                                    兼职
                                </a-radio>
                            </a-radio-group>
                        </div>
                    </div>
                </div>

                <div class="mt20" v-if="serviceman_id==0">
                    <div class="edit-serviceman-jurisdiction">
                        <div class="flex alcenter">
                            <div class="ft14 ftw500 cl-info">权限</div>
                            <div class="ft12 ftw400 cl-info ml8">(可多选)</div>
                        </div>
                        <div class="flex alcenter mt20">
                            <div class="select-check-box active" v-if="form.write_off_jurisdiction==1"
                                 @click="checkWriteOff()">
                                <a-icon type="check" class="cl-w"/>
                            </div>

                            <div class="select-check-box" v-else @click="checkWriteOff()">

                            </div>

                            <div class="ml8 ft14 ftw500 cl-main">核销权限</div>

                            <!--							<div class="ml8 ft12 ftw400 cl-info">(可核销优惠券、扣除余额、扣除积分)</div>-->
                        </div>

                        <div class="mt20">
                            <div class="flex ">
                                <div class="ft12 ftw500 cl-info" style="width: 150px;">加值权限：</div>

                                <div class="ml2">
                                    <div class="flex wrap">
                                        <div class="flex alcenter mr10 mb10" v-for="(item,index) in jurisdiction">
                                            <div class="select-check-box active"
                                                 v-if="form.add_value_jurisdiction.indexOf(item.id)!=-1"
                                                 @click="removeSelectId(index)">
                                                <a-icon type="check" class="cl-w"/>
                                            </div>

                                            <div class="select-check-box " v-else @click="addSelectId(index)">

                                            </div>
                                            <div class="ml8 ft14 ftw500 cl-main">{{ item.name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </a-spin>
        </a-modal>
    </div>
</template>

<script>
import uploadImg from '@/components/upload/upload.vue'
import moment from 'moment';

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        serviceman_id: {
            type: Number,
            default: 0
        },
    },
    components: {
        uploadImg,
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            dateFormat: 'YYYY-MM-DD',
            form: {
                name: '',
                face: '',
                mobile: '',
                job_number: '',
                education_id: '0',
                write_off_jurisdiction: 0,
                add_value_jurisdiction: [],
                aptitude_list: [],
                select_servers: [],
                employ: 0,
                birthday: '1990-01-01',
                status:true,
                sex: 2,
                shop_id: null,

            },
            service_lists: [],
            shop_list: [],
            aptitude_list: [],
            education_list:[],
            jurisdiction: [
                // {id:'1',name:'办理会员'},
                // {id:'2',name:'余额充值'},
                // {id:'3',name:'积分充值'},
                // {id:'4',name:'送券'},
                {id: '5', name: '订单管理'},
                // {id:'6',name:'囤货订单'},
                {id: '7', name: '代客下单'},
                // {id:'8',name:'线下余额补单'},
                // {id:'9',name:'相册管理'},
                // {id:'10',name:'办理次卡'},
                {id: '11', name: '预约管理'},
                {id: '12', name: '服务人员我的预约'}, //服务人员能操作的权限
                // {id:'13',name:'服务人员我的排班'},
                {id: '14', name: '业绩填单'}
            ]
        }
    },
    computed: {
        getTitle() {
            if (this.serviceman_id == 0) {
                return "添加服务人员";
            } else {
                return "编辑服务人员"
            }
        }
    },
    created() {
        this.loaddata();
    },
    methods: {
        moment,
        loaddata() {
            //if (this.serviceman_id == 0) return;
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('platform/showServiceman', {
                serviceman_id: this.serviceman_id,
            }).then(res => {
                if (res.form) {
                    this.form = res.form;
                    this.form.status = res.form.status == -1 ?false:true;
                }
                this.aptitude_list = res.aptitude_list;
                this.service_lists = res.service_lists;
                this.shop_list = res.shop_list;
                this.education_list=res.education_list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
                this.$router.go(-1);
            })
        },
        changeTime(date, dateString) {
            this.form.birthday = dateString;
        },
        /**
         * 选中
         * @param {Object} index
         */
        addSelectId(index) {
            if (this.form.add_value_jurisdiction.indexOf(this.jurisdiction[index].id) != -1) {
                this.$message.error("您已经选择过这个了");
            }
            this.form.add_value_jurisdiction.push(this.jurisdiction[index].id);
            console.log(this.form.add_value_jurisdiction);
        },

        /**
         * 取消选择
         * @param {Object} index
         */
        removeSelectId(index) {
            let startIndex = this.form.add_value_jurisdiction.indexOf(this.jurisdiction[index].id);
            if (startIndex == -1) {
                this.$message.error("您已经取消过这个了");
            }
            this.form.add_value_jurisdiction.splice(startIndex, 1);
        },

        checkWriteOff() {
            if (this.form.write_off_jurisdiction == 1) {
                this.form.write_off_jurisdiction = 0;
            } else {
                this.form.write_off_jurisdiction = 1;
            }
        },

        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },

        /**
         * 提交数据
         */
        onSubmit() {
            if (this.confirmLoading == true) return;
            this.confirmLoading = true;
            this.$http.api('platform/saveServiceman', {
                serviceman_id: this.serviceman_id,
                name: this.form.name,
                mobile: this.form.mobile,
                face: this.form.face,
                education_id: this.form.education_id,
                job_number: this.form.job_number,
                write_off_jurisdiction: this.form.write_off_jurisdiction,
                add_value_jurisdiction: this.form.add_value_jurisdiction,
                aptitude_list: this.form.aptitude_list,
                select_servers: this.form.select_servers,
                employ: this.form.employ,
                sex: this.form.sex,
                birthday: this.form.birthday,
                status: this.form.status ? 1 : -1,
                shop_id: this.form.shop_id,
            }).then(res => {
                this.$message.success('保存成功', 1, () => {
                    this.confirmLoading = false;
                    this.$emit('ok');
                })

            }).catch(res => {
                console.log(res);
                this.confirmLoading = false;
            })
        },

    }

}
</script>

<style>

.edit-serviceman-jurisdiction {
    width: 481px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EBEDF5;
    padding: 16px;
}

.select-check-box {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid #A6AEC2;
    cursor: pointer;
}

.select-check-box.active {
    background: #4772FF;
    cursor: pointer;
    border: none;
    line-height: 20px;
    text-align: center;
}

</style>
